<template>
  <div>
    <headers />
    <div class="content">
      <img src="@/assets/images/shopBj.png" />
      <div class="title">积分商城</div>
      <div class="title2">功能暂未上线，敬请期待</div>
    </div>
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { getRecProductType, getProducts } from "@/api/products";

export default {
  name: "Procucts",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 737px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .title {
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    line-height: 59px;
    margin-top: 256px;
  }
  .title2 {
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 25px;
    margin-top: 20px;
  }
}
</style>